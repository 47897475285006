import * as directives from 'vuetify/directives'
import {VTooltip} from 'vuetify/components/VTooltip'
import {VCard} from 'vuetify/components/VCard'
import {VBtn} from 'vuetify/components/VBtn'
import {VTextField} from 'vuetify/components/VTextField'
import {VCheckbox} from 'vuetify/components/VCheckbox'
import {VSelect} from 'vuetify/components/VSelect'
import {VAutocomplete} from 'vuetify/components/VAutocomplete'
import {VCombobox} from 'vuetify/components/VCombobox'
import {VInput} from 'vuetify/components/VInput'
import {VField} from 'vuetify/components/VField'
import {VCode} from 'vuetify/components/VCode'
import * as labsComponents from 'vuetify/labs/components'

export const isDev = false
export function vuetifyConfiguration() {
  const options = JSON.parse('{"theme":{"defaultTheme":"light","themes":{"light":{"dark":false,"colors":{"primary":"#1976D2","secondary":"#424242","accent":"#82B1FF","error":"#FF5252","info":"#2196F3","success":"#4CAF50","warning":"#FFC107","on-warning":"#1E1E1E","on-background":"#1E1E1E","on-surface":"#1E1E1E"},"variables":{"high-emphasis-opacity":0.9,"medium-emphasis-opacity":0.7,"disabled-opacity":0.7,"border-color":"#1E1E1E","theme-on-code":"#1E1E1E"}}}},"defaults":{"SCode":{"tag":"code"},"STooltip":{"transition":"fade-transition","openDelay":200},"SCard":{"variant":"outlined"},"SBtn":{"variant":"flat"},"SBtnPrimary":{"variant":"flat","color":"primary"},"STextField":{"persistentHint":true,"hideDetails":"auto","maxErrors":100,"variant":"outlined","spellcheck":"false"},"SCheckbox":{"persistentHint":true,"hideDetails":"auto","maxErrors":100},"SSelect":{"persistentHint":true,"hideDetails":"auto","maxErrors":100,"variant":"outlined"},"SAutocomplete":{"persistentHint":true,"hideDetails":"auto","maxErrors":100,"variant":"outlined","spellcheck":"false"},"SCombobox":{"persistentHint":true,"hideDetails":"auto","maxErrors":100,"variant":"outlined","spellcheck":"false"},"SInput":{"persistentHint":true,"hideDetails":"auto","maxErrors":100},"SField":{"variant":"outlined","spellcheck":"false"}}}')
  options.directives = directives
  options.aliases = {'STooltip': VTooltip,'SCard': VCard,'SBtn': VBtn,'SBtnPrimary': VBtn,'STextField': VTextField,'SCheckbox': VCheckbox,'SSelect': VSelect,'SAutocomplete': VAutocomplete,'SCombobox': VCombobox,'SInput': VInput,'SField': VField,'SCode': VCode}
  options.components = {...labsComponents}
  
  return options
}

